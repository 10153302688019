"use strict";

;
(function ($) {
    /*
     * Global Varible
     */
    let $window = $(window),
        $document = $("document"),
        $body = $("body");


    /*
     * Offcanvas Menu
     */
    let $menuTrigger = $("#js-menu-trigger"),
        $menuClose = $(".overlay");

    // Trigger Menu Toggle
    $menuTrigger.on("click", () => {
        menuToggle();
    });

    // Trigger Menu Close
    $menuClose.on("click", () => {
        menuClose();
    });

    // Menu Toggle
    let menuToggle = () => {

        if ($body.hasClass("menu-is-active")) {

            $body.removeClass("menu-is-active");

        } else {

            $body.addClass("menu-is-active");

        }

    };

    // Menu Close
    let menuClose = () => {

        $body.removeClass("menu-is-active");

    };

    let $submenuTrigger = $(".has-sub"),
        $subMenu = $(".main-nav-list-sub");

    $submenuTrigger.on("click",function() {

        if ($(this).hasClass("active")) {
            $(this).find(".main-nav-list-sub").slideUp();
            $(this).removeClass("active");

        } else {
            $submenuTrigger.removeClass("active");
            $(this).addClass("active");

            $subMenu.slideUp();
           $(this).find(".main-nav-list-sub").slideToggle();
        }
    });
    
    /* 
     *  Home slider
     */
    var $homeSlider = $('.home-slider');
    if ($homeSlider.length > 0) {
        $homeSlider.owlCarousel({
            items: 1,
            nav: true,
            navText: ['', ''],
            navSpeed: 1000,
            dots: false,
            autoplay: true,
            autoplayTimeout: 3000,
            autoplayHoverPause: true,
            autoplaySpeed: 1000,
            loop: true
        });
    }

  /* 
   * Data background
   */
  $('[data-background]').each(function(){
    var bg = $(this).attr('data-background');
    if( bg.match('^rgb') || bg.match('^#') ){
      $(this).css('background-color', bg);
    }else{
      $(this).css('background-image', 'url('+bg+')');
    }
  });

    /* 
     * News Event Carousel
     */
    var $newsEvent = $('.news-event-carousel');
    if ($newsEvent.length > 0) {
         $newsEvent.owlCarousel({
          loop:true,
          margin:28,
          dots: true,
          autoplay:true,
          autoplayTimeout:3000,
          autoplayHoverPause:true,
          autoplaySpeed: 1000,
          responsive:{
              0:{
                  items:1
              },
              700:{
                  items:2
              },
              1000:{
                  items:3
              }
          }
        });
    }


    /* 
     * News Event Carousel
     */
    var $managmentCarousel = $('.management--carousel');
    if ($managmentCarousel.length > 0) {
         $managmentCarousel.owlCarousel({
          loop:true,
          margin:28,
          dots: true,
          autoplay:true,
          autoplayTimeout:3000,
          autoplayHoverPause:true,
          autoplaySpeed: 1000,
          responsive:{
              0:{
                  items:1
              },
              600:{
                  items:2
              },
              800:{
                  items:3
              },             
              1024:{
                  items:4
              }
          }
        });
    }


    // News Event Grid
    // var $newsEventEl = $('.news-event--grid');
    
    // if($newsEventEl.length > 0) { 
      
    //     var $newsEventGrid = $newsEventEl.masonry({
    //        columnWidth: '.news-event__sizer',
    //        gutter: '.news-event__gutter-sizer',
    //        itemSelector: '.news-event__item',
    //        percentPosition: true
    //     });

    //     // layout Masonry after each image loads
    //     $newsEventGrid.imagesLoaded().progress( function() {
    //        $newsEventGrid.masonry('layout');
    //     });
    // }  

    /*
      Contact form
    */

    $("#contact-form").validate({
    errorElement: "em",

    rules: {
        'name': {
            required: true
        },
        emailid: {
            required: true,
            email: true
        },
        'mobileno': {
            required: true,
            number: true
        },
        'message': {
            required: true
        }

    },

    messages: {

        'name': {
            required: "Please, enter name"
        },
        
        'emailid': {
            required: "Please, enter email address",
            email: "Please, enter valid email address"
        },

        'mobileno': {
            required: "Please, enter mobile number",
            number: "Please, enter valid mobile number"
        },
        'message': {
            required: "Please, enter message",
        }

    },

    submitHandler: function () {
        
        // Attach usefull variable with form data
         var data = $('#contact-form').serialize(),
             form_url = $('#contact-form').attr('action');
              $('.contact-btn').addClass('load');
              $.ajax({
                type: 'POST',
                url: form_url,
                data: data,
                success: function(data){
                    //Do something success
                    data = $.parseJSON(data);
                    $('.contact-btn').removeClass('load');
                    if(data.sent === true) {
                        $("#contact-message").html("<div class='success-message'><h3>"+data.heading+"</h3><p>"+data.msg+"</p></div>").delay(5000).fadeOut('slow');          
                        $("input[type!='hidden'], textarea").val("");
                    } else {
                        $('.contact-btn').removeClass('load');
                        $("#contact-message").html(data.msg);
                    }
                  }

              });
        
          } // end submit haldler

    });


    $("#subscribe-form").validate({
    
    errorElement: "em",

    rules: {
       'email': {
            required: true,
            email: true
        }
    },

    messages: {
        'email': {
            required: "Email Required",
            email: "Email Invalid"
        }
    },

    highlight: function(element, errorClass, validClass) {
      $(element).nextAll(".subscribe-form__button").addClass(errorClass).removeClass(validClass).text('Error');
    },
    unhighlight: function(element, errorClass, validClass) {
      $(element).nextAll(".subscribe-form__button").removeClass(errorClass).addClass(validClass).text('Subscribe');
    },

    submitHandler: function () {
         
        // Attach usefull variable with form data
         var data = $('#subscribe-form').serialize(),
             form_url = $('#subscribe-form').attr('action');
              $('.subscribe-form__button').addClass('load');
              $.ajax({
                type: 'POST',
                url: form_url,
                data: data,
                success: function(data){
                    
                    var data = $.parseJSON(data);
                    $('.subscribe-form__button').removeClass('load');

                    if(data.sent === true) {
                    
                        $("#subscribe-message").html(data.msg).delay(5000).fadeOut('slow');          
                        $("input[type!='hidden']").val("");
                    
                    } else {
                    
                        $('.subscribe-form__button').removeClass('load');
                        $("#subscribe-message").html(data.msg);
                    
                    }
                }

              });
        
          } // end submit haldler

    });
        
})(jQuery);